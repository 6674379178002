import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import 'bootswatch/dist/simplex/bootstrap.min.css';
//import 'bootswatch/dist/sketchy/bootstrap.min.css';
//import 'bootstrap/dist/css/bootstrap.min.css';
import './index.css';
import 'bootstrap/dist/js/bootstrap.bundle.min';


ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);


