/* COPYRIGHT PAUL IONESCU - ALL RIGHTS RESERVED */

import './App.css';
import HomePage from './HomePage';
import {BrowserRouter as Router, Route, Switch, Redirect} from 'react-router-dom';

window.getProps = (args) => {
  let lang = "en", pageType = "pages", pageId = "home"
  if(args && args && args.match && args.match.params){
    let params = args.match.params
    lang = params.lang;
    pageType = params.pageType;
    pageId = params.pageId;
  }
  return {
    lang:lang,
    pageType: pageType,
    pageId: pageId
  }
}

function App() {
  

  return (
    <Router>
      <Route path="/" exact={true} component={ChooseLanguage}></Route>
      
      <Switch>
        <Route path="/:lang/:pageType/:pageId" children={(args) => { 
          let {lang, pageType, pageId} = window.getProps(args)
          return <HomePage lang={lang} pageType={pageType} pageId={pageId} />
        }} />
        
        <Route exact path="/:lang" children={(args) => { 
          let lang = args.match.params.lang || "en"
          if(lang.length !== 2) lang = "en"
          return <Redirect to={`/${lang}/pages/home`} />
        }} />
        
        <Route>
          <Redirect to="/en/pages/home" /> 
        </Route>
        
      </Switch>    
      </Router>
  );
}



function ChooseLanguage(){
  let browserLanguage = (window.navigator.userLanguage || window.navigator.language);
  let language = "en";
  if(browserLanguage && browserLanguage.length >= 2){
    browserLanguage = browserLanguage.substr(0,2);
    let rx = new RegExp(/[a-z]/);
    if(browserLanguage.match(rx)){
      language = browserLanguage;
    }
  }
  window.location = `/${language}/pages/home`;

  return <></>
}

export default App;
