/* COPYRIGHT PAUL IONESCU - ALL RIGHTS RESERVED */

import React from "react"
import axios from "axios"
import { Container, Jumbotron, Navbar, Nav, Image, Row, Col} from 'react-bootstrap'
import BasePage from './BasePage'
import ArticlePage from "./ArticlePage"
import ArticleSidebar from "./ArticleSidebar"
import LangSelector from "./LangSelector"
import YamlLoader from "./YamlLoader"

class HomePage extends React.Component {

  constructor(props) {
    super();
    this.state = {
      messagesLoaded : false
    }
  }

  banner = (manifest) => {
    if(!manifest.banner) return <></>
    let images = manifest.banner.images
    let rand = Math.floor(Math.random() * images.length)
    let bannerSrc = images[rand]
    return <Image className="p-4" alt="Page banner" src={bannerSrc} fluid></Image>
  }

  componentDidMount = async() => {
    try {
      let loader = new YamlLoader();
      let messagesYaml = await loader.load("/content/translations.yml");
      let manifest = await axios.get("/manifest.json")
      let articles = await loader.load(`/content/articles/${this.props.lang}/articles-index.yml`);
      let messagesLoaded = false
      if(messagesYaml){ 
        //save the messages in the window for other components
        window.reactSiteTranslations = messagesYaml;
        messagesLoaded = true
      }
      this.setState({
        messagesLoaded: messagesLoaded,
        manifest: manifest.data,
        articles: articles
      })
    
    } catch (error) {
      console.log(error)  
    }
    
  }

  render(){
    if(!this.state.messagesLoaded || !this.state.manifest) return <></>;
    let manifest = this.state.manifest

    let lang = this.props.lang, pageType = this.props.pageType, pageId = this.props.pageId
    let msg = window.reactSiteTranslations
    let articles = this.state.articles
    let has = {
      home: false,
      about: false,
      articles: false,
      services: false,
      contact: false
    }

    if(msg.Home && msg.Home[lang]) has.home = true
    if(msg.About && msg.About[lang]) has.about = true
    if(msg.Articles && msg.Articles[lang]) has.articles = true
    if(msg.Services && msg.Services[lang]) has.services = true
    if(msg.Contact && msg.Contact[lang]) has.contact = true

    let contentWidth = 12
    
    if(has.articles){
      contentWidth = 9
    }

    if(pageType && pageId){
      let title = manifest.short_name;
      if(pageType === "articles"){
        title = `${msg.Articles[lang]} | ${title}`;
      }
      else{
        switch(pageId){
          case "about": title = `${msg.About[lang]} | ${title}`; break;
          case "services": title = `${msg.Services[lang]} | ${title}`; break;
          case "action": title = `${msg.Action[lang]} | ${title}`; break;
          case "contact": title = `${msg.Contact[lang]} | ${title}`; break;
          default: pageId = "home"; break;
        }
      }

      document.title = title;
    }

    let bannerPosition = "above-menu"
    let banner = <></>
    if(manifest.banner){
      banner = this.banner(manifest)
      bannerPosition = manifest.banner.position
    }
    return (
        <>
        <Container className="rbs-container" fluid>
          <Jumbotron id="page-top" className="rbs-jumbotron">
            <LangSelector pageType={pageType} pageId={pageId} msg={msg}></LangSelector>
            <a href="/"><h1>{manifest.short_name}</h1></a>
            <p>{manifest.description}</p>
          </Jumbotron>
          {bannerPosition === "above-nav" && banner}
          <Navbar collapseOnSelect={true} className="rbs-nav" sticky="top" expand="lg">
            <Navbar.Toggle aria-controls="responsive-navbar-nav" className="rbs-nav-link"/>
            <Navbar.Collapse id="responsive-navbar-nav">
              <Nav>
                { has.home && <Nav.Link href={`/${lang}/pages/home`} className="rbs-nav-link">{msg.Home[lang]}</Nav.Link>}
                { has.articles && <Nav.Link href={`/${lang}/articles/home`} className="rbs-nav-link">{msg.Articles[lang]}</Nav.Link> }
                { has.about && <Nav.Link href={`/${lang}/pages/about`} className="rbs-nav-link">{msg.About[lang]}</Nav.Link>}
                { has.services && <Nav.Link href={`/${lang}/pages/services`} className="rbs-nav-link">{msg.Services[lang]}</Nav.Link>}
                { has.contact && <Nav.Link href={`/${lang}/pages/contact`} className="rbs-nav-link">{msg.Contact[lang]}</Nav.Link>}
              </Nav>
            </Navbar.Collapse>
          </Navbar>
          {bannerPosition === "below-nav" && banner}

          <Row className="m-0">
            <Col md={contentWidth} className="p-4 h-100 bg-light">
              {pageType === "articles" && (<ArticlePage lang={lang} articlesId={pageId} articles={articles} ></ArticlePage>)}
              {pageType !== "articles" && (<BasePage lang={lang} pageId={pageId}></BasePage>)}
            </Col>
            { has.articles && <Col className="p-4 h-100 rbs-sidebar">
              <ArticleSidebar lang={lang}></ArticleSidebar>
            </Col>}
          </Row>

          <footer className="px-0 py-2 text-secondary border-top rbs-footer">
            <Row>
              <Col>
                <h5>
                  {manifest.name}
                </h5>
                <p>{manifest.address}</p>
              </Col>
            </Row>
          </footer>
          
        </Container>
        
      </>

        
      );
  }
}

export default HomePage;
