/* COPYRIGHT PAUL IONESCU - ALL RIGHTS RESERVED */

import React from "react";
import ArticleProvider from "./ArticleProvider";
import {Button} from "react-bootstrap";

class ArticleSidebar extends React.Component {

    provider = null;
    lang = "en";

    constructor(props){
        super(props);
        if(this.props.lang) this.lang = this.props.lang;
        if(this.lang){
            this.provider = new ArticleProvider(this.lang);
        }

        this.state = {
            articles : null
        }
    }


    componentDidMount = async() => {

        if(this.provider){
            await this.provider.loadArticles();
            if(this.provider.articles) this.setState({articles:this.provider.articles});
        }
    }

    render(){
        let articles = this.state.articles;
        if(!window.reactSiteTranslations || !articles || 
            !Array.isArray(articles)
             || articles.length === 0) return <></>
        let msg = window.reactSiteTranslations;
        //display only the first 5
        articles = articles.slice(0,5);

        const markup = articles.map((articlesItem, index) => {
            if(!articlesItem) return <></>;
            let title = articlesItem.title;
            if(!title) return <></>;
            let articlesId = title.replace(/[^a-z0-9]/gi,"-");
            return <span key={`articles-${index}`}>
                <p>
                    <a href={`/${this.props.lang}/articles/${articlesId}`}>{title}</a>
                </p>
            </span>
            }
        );

        return <>
                <h3>{msg.LatestArticles[this.lang]}</h3>
                {markup}
                <Button variant="outline-primary" href={`/${this.lang}/articles/home`}>{msg.ReadMore[this.lang]}</Button>
            </>
    }

}
  
export default ArticleSidebar;
  